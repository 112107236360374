import React from 'react';
import Banner from './Banner'
import Blog from '../Blog';
import CardLogos from './CardLogos';
import Footer from '../Footer';
import HowItWorks from './HowItWorks';
import Info from './Info';
import Partners from './Partners';
import Portfolio from './Portfolio';
import DepoCarousel from '../DepoCarousel';

import "bootstrap/dist/css/bootstrap.min.css";

const DirectServiceLandingPage = () => {
  return (
      <>
          <Banner/>
          <Info />
          <HowItWorks />
          <Portfolio />
          <DepoCarousel />
          <Footer/>
      </>
  )
}

export default DirectServiceLandingPage;
