import styled from "styled-components";

export const Sidebar = styled.nav<{ isOpen }>`
    background: rgba(18, 18, 28, 1);
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: calc(100vh - 1vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 98;
    transition: all 0.3s ease-in;
`;

export const NavMenu = styled.div`
    height: 80%;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    button {
        background: #fe8418;
        color: #fff;
        margin: 0 2rem;
        padding: 0.4rem 1.4rem;
        border: none;
        border-radius: 0.9rem;

        transition: ease-in-out 0.2s filter;

        &:hover {
            filter: brightness(0.8);
        }
    }

    select {
        background: none;
        border: none;
        color: #fff;
    }
`;

export const NavLink = styled.a`
    color: #fff;
    display: flex;
    font-size: 1.1rem;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
`;

export const LinkButton = styled.a`
    padding: 0.3rem 0.9rem;
    background: #fe8418;
    font-size: 1rem;
    border-radius: 1.3rem;
    text-decoration: none;
    color: #fff;
    transition: all 0.2s;

    &:hover {
        color: #fff;
        filter: brightness(0.8);
    }
`;
