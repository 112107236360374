import React from 'react';
import styled from 'styled-components';

const DifferencesCardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    max-width: 460px;

    .image-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.65rem;
        height: 5.65rem;
        margin: 0.5rem;
        padding: 0;

        border: 4px solid #fe8418;
        border-radius: 50%;

        img {
            width: 2.3rem;
        }
    }

    .differences-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            color: #2e2e2e;
            max-width: 310px;
            font-size: 1.125rem;
            width: 100%;
            margin: 0;
        }

        strong {
            color: #fe8418;
            font-size: 1.375rem;
            font-weight: bold;
        }
    }

    @media (max-width: 1024px) {
        padding: 0.2rem;
    }

    @media (max-width: 768px) {
        max-width: 750px;
        padding: 0rem;

        .differences-content {
            margin: 0 0.5rem;
        }
    }

    @media (max-width: 414px) {
        max-width: 400px;
        flex-direction: column;

        text-align: center;

        .differences-content {
            text-align: center;

            strong {
                text-align: center;
            }

            p {
                max-width: 350px;
                padding: 0.5rem 0;
            }
        }
    }
`;

const DifferencesCard = ({image, title, children}) => {

    return (
        <DifferencesCardContainer>
            <div className="image-content">
                <img src={image} alt="imagem" />
            </div>
            <div className="differences-content">
                <p><strong>{title}</strong></p>
                <main>{children}</main>
            </div>
        </DifferencesCardContainer>
    )
}
export default DifferencesCard;
