import styled from "styled-components";

export const Background = styled.div`
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("/images/banner-servico-direto_.png") no-repeat;
    background-size: cover;

    height: 100vh;

    @media (max-width: 768px) {
        background-position: center;
    }
`;

export const BgNav = styled.div`
    background: rgba(0, 0, 0, 0.7);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 77px);

    h1 {
        max-width: 840px;
        text-transform: uppercase;
        color: #fe8418;
        font-size: 2.8rem;
        text-align: center;
        font-weight: 900;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);

        @media (max-width: 768px) {
            max-width: 640px;
            font-size: 2.4rem;
        }

        @media (max-width: 414px) {
            max-width: 370px;
            font-size: 2rem;
        }

        @media (max-width: 375px) {
            max-width: 310px;
            font-size: 1.8rem;
        }
    }

    p {
        color: #fff;
        margin-top: 1rem;
        font-size: 1.6rem;
        text-align: center;
        font-weight: 300;

        @media (max-width: 414px) {
            max-width: 370px;
            font-size: 1.4rem;
        }
        @media (max-width: 320px) {
            max-width: 300px;
            font-size: 1.4rem;
        }
    }

    div {
        > a {
            width: 2.7rem;
            height: 2.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: 3px solid #fe8418;
            border-radius: 50%;
            padding: 0.5rem;

            position: absolute;
            bottom: 2rem;
            transition: all 0.2s;
            left: 50%;

            &:hover {
                background: #fe8418;
                border-color: #ccc;
            }

            @media (max-width: 320px) {
                bottom: 1.5rem;
            }
        }
    }
`;

export const Link = styled.a`
    text-decoration: none;
    color: #fff;
    background: #fe8418;
    padding: 0.3rem 0.8rem;
    border-radius: 0.9rem;

    margin-top: 1rem;

    &:hover {
        filter: brightness(0.8);
        color: #fff;
    }
`;

