import styled from "styled-components";

export const Content = styled.div`
    margin: 3rem 0;

    .slick-slide {
        margin-top: 1rem;
    }
`;

export const Title = styled.div`
    span {
        font-size: 1.875rem;
    }
    p {
        font-size: 2.375rem;
        line-height: 2.2rem;
        position: relative;

        strong {
            color: #fe8418;
        }

        &:before {
            content: "";
            width: 16%;
            height: 2px;
            background: #000;
            position: absolute;
            top: 90%;
            right: 2%;
        }
    }

    @media (max-width: 1024px) {
        span {
            font-size: 1.675rem;
        }

        p {
            font-size: 2rem;

            &:before {
                width: 17%;
            }
        }
    }

    @media (max-width: 768px) {
        span {
            font-size: 1.475rem;
        }

        p {
            font-size: 1.8rem;

            &:before {
                content: none;
            }
        }
    }

    @media (max-width: 414px) {
        text-align: center;
        p {
            font-size: 1.5rem;
        }
    }
`;

export const TitleAct = styled.div`
    margin: 3rem 0;
    span {
        font-size: 1.875rem;
    }
    p {
        font-size: 2.375rem;
        line-height: 2.2rem;
        position: relative;

        strong {
            color: #fe8418;
        }

        &:before {
            content: "";
            width: 30%;
            height: 2px;
            background: #000;
            position: absolute;
            top: 90%;
            right: 2%;
        }
    }

    @media (max-width: 1024px) {
        span {
            font-size: 1.675rem;
        }

        p {
            font-size: 2rem;

            &:before {
                width: 30%;
            }
        }
    }

    @media (max-width: 768px) {
        span {
            font-size: 1.475rem;
        }

        p {
            font-size: 1.8rem;

            &:before {
                content: none;
            }
        }
    }

    @media (max-width: 414px) {
        text-align: center;
        p {
            font-size: 1.5rem;
        }
    }
`;

export const CircleContent = styled.div`
    margin: 2rem 0;

    .slick-arrow {
        width: 2rem;
        height: 2rem;

        :before {
            content: none;
        }
    }

    .slick-next {
        padding-left: 12px;
        padding-top: 8px;
    }

    .slick-prev {
        padding-left: 12px;
        padding-top: 8px;
    }

    @media (max-width: 414px) {
        .slick-next {
            top: 35%;
            right: 7%;
        }

        .slick-prev {
            top: 35%;
            left: 7%;
        }
    }
`;

export const CardCircle = styled.div<{ isAgenda }>`
    text-align: center;
    position: relative;

    .image-content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 10.5rem;
        height: 10.5rem;

        border: 1px solid #fe8418;
        border-radius: 50%;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 9.5rem;
            height: 9.5rem;
            background: #fe8418;
            border-radius: 50%;
        }

        img {
            width: ${(props) => (props.isAgenda === true ? "4.25rem" : "6.25rem")};
        }
    }

    span {
        position: absolute;
        background: rgba(254, 132, 24, 1);
        padding: 0.3rem 0.7rem;
        color: #fff;
        font-weight: bold;
        border-radius: 50%;
        z-index: 999;
        top: -2%;
        right: 30%;
    }

    p {
        max-width: 17.25rem;
        margin: 0 auto;
        padding-top: 1rem;
    }

    @media (max-width: 1024px) {
        span {
            right: 27%;
        }
    }

    @media (max-width: 768px) {
        span {
            right: 18%;
        }

        p {
            max-width: 15rem;
            font-size: 1rem;
        }
    }

    @media (max-width: 414px) {
        span {
            right: 30%;
        }
    }
    @media (max-width: 375px) {
        span {
            right: 26%;
        }
    }
`;

export const CategoryContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    > a {
        margin: 3rem 0 1rem 0;
        padding: 0.7rem 3rem;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        background: rgba(254, 132, 24, 1);
        border-radius: 6.25rem;
        font-weight: bold;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8);
        }
    }

    @media (max-width: 768px) {
        max-width: 720px;

        .slick-slider {
            max-width: 620px;
        }

        .slick-next {
            right: -3rem;
        }

        .slick-prev {
            left: -3rem;
        }
    }

    @media (max-width: 414px) {
        max-width: 390px;

        .slick-slider {
            max-width: 280px;
        }
    }

    @media (max-width: 375px) {
        .slick-slider {
            max-width: 280px;
        }

        .slick-next {
            right: -2.3rem;
        }

        .slick-prev {
            left: -2.3rem;
        }
    }

    @media (max-width: 360px) {
        .slick-slider {
            max-width: 220px;
        }
    }
`;

export const DiferencesContent = styled.div`
    margin-top: 3rem;
    position: relative;

    span {
        font-size: 1.8rem;
        text-align: right;
        position: absolute;
        right: 0;
        top: -10%;
    }

    > p {
        color: #000000;
        font-size: 2.3rem;
        text-align: right;
        margin: 0;

        strong {
            color: #fe8418;
            font-weight: bold;
        }

        &:before {
            content: "";
            width: 66%;
            height: 2px;
            background: #000;
            position: absolute;
            top: 9%;
            left: 0%;
        }
    }

    @media (min-width: 1024px) {
        > p {
            &:before {
                width: 60%;
                top: 9.5%;
            }
        }
    }

    @media (max-width: 768px) {
        .slick-next {
            top: 50%;
            right: 2%;
        }

        .slick-prev {
            top: 50%;
            left: 2%;
        }

        > p {
            font-size: 2rem;

            &:before {
                width: 45%;
                top: 17%;
            }
        }
    }

    @media (max-width: 414px) {
        .slick-next {
            top: 27%;
            right: 2%;
        }

        .slick-prev {
            top: 27%;
            left: 2%;
        }
        .wrap-carousel {
            margin-top: 3rem;
        }
        span {
            margin-right: 8rem;
            top: -10.5%;
        }

        > p {
            text-align: center;
            font-size: 1.6rem;

            &:before {
                width: 90%;
                top: 14%;
                left: 6%;
            }
        }
    }

    @media (max-width: 360px) {
        max-width: 350px;

        span {
            margin-right: 6rem;
        }
        p {
            &:before {
                /* width: 95%;
                top: 14%;
                left: 2%; */
            }
        }
    }

    .differences {
        position: relative;

        margin-top: 2rem;
        margin-bottom: 5rem;

        .slick-slider {
            div {
                margin: 0 auto;

                @media (max-width: 768px) {
                    margin: 0 0.5rem;
                }

                @media (max-width: 414px) {
                    margin: 0 auto;
                }
            }

            @media (max-width: 768px) {
                max-width: 700px;
            }
        }

        > p {
            color: #000000;
            font-size: 2.375rem;
            text-align: right;
            margin: 0;

            strong {
                color: #fe8418;
                font-weight: bold;
            }

            &:before {
                content: "";
                width: 58%;
                height: 2px;
                background: #000;
                position: absolute;
                top: 12%;
                left: 0%;
            }
        }

        @media (max-width: 1024px) {
            > p {
                &:before {
                    width: 38%;
                    top: 17%;
                    left: 1%;
                }
            }
        }

        @media (max-width: 768px) {
            margin-bottom: 0;

            > p {
                font-size: 2rem;
                text-align: center;
                margin-bottom: 3rem;

                &:before {
                    content: "";
                    width: 60%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    top: 20%;
                    left: 20%;
                }
            }

            .slick-next {
                top: 25%;
                right: -3%;
            }

            .slick-prev {
                top: 25%;
                left: -3%;
            }
        }

        @media (max-width: 414px) {
            .slick-next {
                top: 13%;
                right: 0%;
            }

            .slick-prev {
                top: 13%;
                left: 5%;
            }
            .wrap-carousel {
                margin-top: 4rem;
            }

            > p {
                text-align: center;
                font-size: 1.4rem;

                &:before {
                    content: "";
                    width: 80%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    top: 10%;
                    left: 10%;
                }
            }
        }

        @media (max-width: 360px) {
            max-width: 350px;
        }
    }
`;
