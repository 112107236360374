import React from 'react';
import styled from "styled-components";
import { IoMdArrowDropright } from 'react-icons/io'

type CardProps = {
    image: string;
    title: string;
}

const Content = styled.div`
    position: relative;
    margin: 1rem;
    display: flex;
    align-items: center;

    img {
        width: 12.5rem;
        height: 12.5rem;
        box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: .9rem;
    }

    div {
        position: absolute;
        background: #fff;
        bottom: 0;
        width: 12.5rem;
        border-bottom-left-radius: .9rem;
        border-bottom-right-radius: .9rem;
        padding: .5rem 0;

        transition: filter .2s;

        &:hover {
            filter: brightness(0.9);
        }


       a {
            display: flex;
            align-items: center;
            justify-content: space-around;
            text-decoration: none;
            color: inherit;

            p {
                margin: 0;
            }
       }
    }

    @media (max-width:1024px){
        margin: 0.5rem;
        img {
            width: 11.5rem;
            height: 11.5rem;
        }

        div {
            width: 11.5rem;
        }
    }

    @media (max-width:414px){
        img {
            width: 8rem;
            height: 8rem;
        }

        div {
            width: 8rem;

            a {
                font-size: .8rem;
            }
        }
    }

    @media (max-width:360px){
        img {
            width: 6rem;
            height: 6rem;
        }

        div {
            width: 6rem;

            a {
                font-size: .7rem;
            }
        }
    }
`

const CardCategory = ({image, title}:CardProps) => {
  return (
      <Content >
           <img src={image} alt="imagem" />
            <div>
                <a target="_blank" href="/book">
                    <p>{title}</p>
                    <IoMdArrowDropright size={24}/>
                </a>
            </div>
      </Content>
  )
}

export default CardCategory;


export const categories = [
    {
        id: '01',
        image: '/images/direto-book-alimentos.jpg',
        title: 'Alimentos'
    },
    {
        id: '02',
        image: '/images/direto-book-ensaios.jpg',
        title: 'Ensaios'
    },
    {
        id: '03',
        image: '/images/direto-book-imoveis.jpg',
        title: 'Imóveis'
    },
    {
        id: '04',
        image: '/images/direto-book-festa.jpg',
        title: 'Festas'
    },
    {
        id: '05',
        image: '/images/direto-book-formatura.jpg',
        title: 'Formaturas'
    },
    {
        id: '06',
        image: '/images/direto-book-casamento.jpg',
        title: 'Casamentos'
    },
    {
        id: '07',
        image: '/images/direto-book-corporativo.jpg',
        title: 'Corporativo'
    },
    {
        id: '08',
        image: '/images/direto-book-pet.jpg',
        title: 'Pet'
    },
    {
        id: '09',
        image: '/images/direto-book-ecommerce.jpg',
        title: 'E-commerce'
    },
    {
        id: '10',
        image: '/images/direto-book-outros.jpg',
        title: 'Outros'
    },
]
