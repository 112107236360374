import React, {useState, useEffect} from 'react';
import Image from 'next/image'
import SideBar from '../SideBar';
import { Nav,NavMenu, NavLink, BgWrap, LinkButton} from './styles'
import ToggleMenu from '../SideBar/ToggleMenu';

type linkProps ={
        id:string,
        link:string,
        target:string,
        label: string
}

export type Navbarprops = {
    links: linkProps[]
    button?: {
        hasButton:boolean,
        link: string,
        label:string
    }
    isOpen?:boolean
}

const NavBar = ({links, button}:Navbarprops) => {
  const [openSideBar, setOpenSideBar] = useState(false);

  const OpenSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  return (
    <BgWrap>
        <Nav>
          <SideBar isOpen={openSideBar} links={links} button={button}/>
            <NavLink href="/">
                <Image src="/images/fotop-servicos-01.svg" width={190} height={32}/>
            </NavLink>
            <ToggleMenu toogle={OpenSideBar} isOpen={openSideBar}/>

          <NavMenu>
           { links.map(link => (
                <NavLink key={link.id} href={link.link} target={link.target}>
                    {link.label}
                </NavLink>
            ))}

            {
                !button ? ("") : (
                    button.hasButton && (
                        <LinkButton href={button.link}>
                            {button.label}
                        </LinkButton>
                    )
                )
            }

          </NavMenu>

        </Nav>
    </BgWrap>
  )
}

export default NavBar;
