import React from 'react';
import { Container, Carousel } from 'react-bootstrap'

import { Content, Wrap } from './styles';

const Info = () => {

  return (
      <Container>
          <Wrap id="information">
            <Carousel fade className="carouselWidth">
                  <Carousel.Item>
                        <img src="/images/slide-show-1.jpg" alt="imagem" />
                  </Carousel.Item>
                  <Carousel.Item>
                        <img src="/images/slide-show-2.jpg" alt="imagem" />
                  </Carousel.Item>
                  <Carousel.Item>
                        <img src="/images/slide-show-3.jpg" alt="imagem" />
                  </Carousel.Item>
                  <Carousel.Item>
                        <img src="/images/slide-show-4.jpg" alt="imagem" />
                  </Carousel.Item>
              </Carousel>
            <Content>
                <div>
                    <p>
                        Ideal para empresas e pessoas que precisam de sessões de fotos pontuais e queiram tratar diretamente com o fotógrafo.
                    </p>
                    <br />
                    <p>
                        Uma foto profissional valoriza seu imóvel, destaca seu produto e eterniza suas lembranças.
                    </p>
                    <br />
                    <p>
                        Aumente suas chances de fechar negócios, deixe um fotógrafo capturar o melhor do seu espaço e os detalhes do seu produto
                    </p>
                </div>
            </Content>
          </Wrap>
      </Container>
  )
}

export default Info;
