import styled from "styled-components";

export const Content = styled.div`
    margin-top: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    .slick-arrow {
        width: 2rem;
        height: 2rem;

        :before {
            content: none;
        }
    }

    .slick-next {
        padding-left: 12px;
        padding-top: 8px;

        @media (max-width: 768px) {
            right: -15%;
        }
    }

    .slick-prev {
        padding-left: 12px;
        padding-top: 8px;

        @media (max-width: 768px) {
            left: -15%;
        }
    }
    .slick-slider {
        div {
            margin: 0 auto;

            @media (max-width: 768px) {
                margin: 0 auto;
            }
        }
    }

    .productb-offer {
        margin: 1rem;
        width: 100%;
        position: relative;

        p {
            color: #fff;
            font-size: 2.3rem;
            strong {
                color: #fe8418;
            }

            &:before {
                content: "";
                width: 84%;
                height: 2px;
                background: #fff;
                position: absolute;
                top: 58%;
                right: 1%;

                @media (max-width: 1024px) {
                    width: 80%;
                    right: 1%;
                }

                @media (max-width: 768px) {
                    width: 75%;
                }
                @media (max-width: 414px) {
                    content: none;
                }
            }

            @media (max-width: 414px) {
                margin-top: 2rem;
                font-size: 2rem;
                text-align: center;
            }
        }
    }

    .service-wrap {
        width: 100%;
        .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .slick-arrow {
            width: 2rem;
            height: 2rem;

            :before {
                content: none;
            }
        }

        .slick-next {
            padding-left: 12px;
            padding-top: 8px;
        }

        .slick-prev {
            padding-left: 10px;
            padding-top: 8px;
        }

        @media (max-width: 768px) {
            max-width: 600px;

            .slick-next {
                top: 35%;
                right: -3rem;
            }

            .slick-prev {
                top: 35%;
                left: -3rem;
            }
        }

        @media (max-width: 414px) {
            .slick-list {
                max-width: 300px;
            }
            .slick-next {
                right: 8%;
            }

            .slick-prev {
                left: 8%;
            }

            a {
                padding: 0.8rem 2rem;
                font-size: 0.9rem;
                margin: 0;
            }
        }

        @media (max-width: 360px) {
            .slick-list {
                max-width: 300px;
            }

            a {
                font-size: 0.8rem;
            }
        }
    }

    .product {
        text-align: center;
        margin: 1rem;

        .image-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 10.5rem;
            height: 10.5rem;

            border: 1px solid #fe8418;
            border-radius: 50%;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9.5rem;
                height: 9.5rem;
                background: #fe8418;
                border-radius: 50%;
            }

            img {
                width: 4.25rem;
            }
        }

        p {
            color: #fff;
        }
        strong {
            color: #fff;
            text-transform: uppercase;
        }
    }
`;
