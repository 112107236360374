import React, {Dispatch, SetStateAction} from 'react';
import { Sidebar, NavMenu, NavLink, LinkButton } from './styles';
import { Navbarprops } from '../NavBar'


const SideBar = ({isOpen, links, button}:Navbarprops) => {

  return (
    <>
      {isOpen ? (
            <Sidebar isOpen={isOpen}>
              <NavMenu>
                { links.map(link => (
                        <NavLink key={link.id} href={link.link} target={link.target}>
                            {link.label}
                        </NavLink>
                    ))}

                    {
                        !button ? ("") : (
                            button.hasButton && (
                                <LinkButton href={button.link}>
                                    {button.label}
                                </LinkButton>
                            )
                        )
                    }
          </NavMenu>
          </Sidebar>
      ) : ""}
    </>
  )
}

export default SideBar;
