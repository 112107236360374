import React from 'react';
import Slider, {CustomArrowProps} from 'react-slick';
import { Container} from 'react-bootstrap'
import styled from 'styled-components'
import { Content } from './styles';

const Background = styled.div`
    background: linear-gradient(81.56deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.72) 99.99%, rgba(0, 0, 0, 0.52) 100%);
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.25);

    margin-bottom: 2rem;
`

const NextArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          background: 'orange',
          zIndex: 10,
          borderRadius: '50%'
        }}
        onClick={onClick}
      >
        <img src="/images/arrow-right.png" />
      </div>
    )
  }

  const PrevArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          background: 'orange',
          zIndex: 10,
          borderRadius: '50%'
        }}
        onClick={onClick}
      >
        <img src="/images/arrow-left.png" />
      </div>
    )
  }

const Portfolio: React.FC = () => {

    const settingProducts = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,

        responsive: [

            {
                breakpoint: 769,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
            },
            {
                breakpoint: 414,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              }
        ],
      }

  return (
    <Background>
      <Container>
          <Content>
                <div className="productb-offer" id="servico">
                    <p> <strong> Portfólio</strong></p>
                </div>

                <div className="service-wrap">
                    <Slider {...settingProducts}>
                        <div className="product">
                            <div className="image-content">
                                <div>
                                    <img src="/images/camera_.png" alt="foto" />
                                </div>
                            </div>
                            <br/>
                            <strong>PRODUTOS</strong>
                        </div>

                        <div className="product">
                            <div className="image-content">
                                <div>
                                    <img src="/images/calendar.png" alt="foto" />
                                </div>
                            </div>
                            <br/>
                            <strong>EVENTOS</strong>
                        </div>
                        <div className="product">
                            <div className="image-content">
                                <div>
                                    <img src="/images/predio.png" alt="foto" />
                                </div>
                            </div>
                            <br/>
                            <strong>CORPORATIVO</strong>
                        </div>
                        <div className="product">
                            <div className="image-content">
                                <div>
                                    <img src="/images/mundo.png" alt="foto" />
                                </div>
                            </div>
                            <br/>
                            <strong>DRONE</strong>
                        </div>

                        <div className="product">
                            <div className="image-content">
                                <div>
                                    <img src="/images/pc.png" alt="foto" />
                                </div>
                            </div>
                            <br/>
                            <strong>TOUR VIRTUAL</strong>
                            <p>(2D / MATTERPORT)</p>
                        </div>
                    </Slider>
                </div>
          </Content>
      </Container>
    </Background>
  )
}

export default Portfolio;
