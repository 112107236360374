import React from 'react';
import Image from  'next/image'
import { Container} from 'react-bootstrap'
import NavBar from '../NavBar';
import { Background, BgNav, Content, Link } from './styles';

const Banner = () => {

    const linksNavbar = [
        {
            id: '01',
            link:'https://fotop.io',
            target:'_blank',
            label:'Sou Fotógrafo'
        },
        {
            id: '03',
            link:'https://fotop.com/fotos/r/WPSWv0',
            target:'_blank',
            label:'Contato'
        },
        {
            id: '04',
            link:'/login',
            target:'',
            label:'Login'
        },

    ]

    const buttonBusness = {
        hasButton: true,
        link: "http://business.fotografoja.com/sou-fotografo/",
        label: "Sou fotógrafo"
    }

  return (
      <Background>
         <BgNav>
               <NavBar links={linksNavbar} />
         </BgNav>

          <Container>
              <Content>
                  <div>
                      <h1>
                          Já pensou em postar um job hoje e contratar o fotógrafo certo para ele amanhã? O Fotop resolve
                          pra você em poucos cliques
                      </h1>

                  </div>

                  <Link href="/marketplace/book">
                      SOLICITAR UM FOTÓGRAFO
                  </Link>

                  <div>
                      <a href="#information">
                          <Image src="/images/arrow-down.png" alt="seta para baixo" width={22} height={18} />
                      </a>
                  </div>

              </Content>
          </Container>
      </Background>
  )
}

export default Banner;
