import styled from "styled-components";

export const Wrap = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 3.875rem 0;
    overflow: hidden;

    img {
        max-width: 33.125rem;
        max-height: 25rem;

        width: 100%;
        border-radius: 1.875rem;
    }

    .carouselWidth {
        width: 530px;
        text-align: center;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 420px) {
        img {
            max-width: 22.375rem;
            max-height: 16.75rem;
        }
    }
`;

export const Content = styled.div`
    div {
        display: flex;
        flex-direction: column;
        max-width: 530px;

        p {
            margin: 0rem 1rem;
            font-size: 1.375rem;
        }
    }

    @media (max-width: 1024px) {
        img {
            max-width: 31.125rem;
            max-height: 23rem;
        }

        div {
            p {
                font-size: 1.275rem;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        div {
            margin-top: 1rem;
        }
    }

    @media (max-width: 414px) {
        img {
            max-width: 20rem;
            max-height: 15rem;
        }

        div {
            p {
                font-size: 1.1rem;
            }
        }
    }

    @media (max-width: 320px) {
        img {
            max-width: 18rem;
            max-height: 13rem;
        }
    }
`;
