import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import CardCategory, { categories } from "./CardCategory";
import DifferencesCard from "./DifferencesCard";
import { NextArrowCarusel, PrevArrowCarusel } from "~/shared/ArrowsCarousel";

import { Content, Title, TitleAct, CircleContent, CardCircle, CategoryContent, DiferencesContent } from "./styles";

const HowItWorks: React.FC = () => {
    const setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 414,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const differencesSetting = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        rows: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 414,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                },
            },
        ],
    };

    const categorySetting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        rows: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    rows: 2,
                    arrows: true,
                },
            },

            {
                breakpoint: 415,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    rows: 2,
                    arrows: true,
                },
            },
        ],
    };

    return (
        <Container>
            <Content>
                <Title>
                    <span>Entenda como funciona:</span>
                    <p>
                        <strong>Agende uma sessão fotográfica</strong> em poucos minutos
                    </p>
                </Title>
                <CircleContent>
                    <Slider {...setting}>
                        <CardCircle isAgenda={false}>
                            <div className="image-content">
                                <div>
                                    <img src="/images/chat.png" alt="imagem" />
                                </div>
                            </div>
                            <span>1</span>
                            <p>Você solicita uma sessão fotográfica e determina quanto quer pagar</p>
                        </CardCircle>

                        <CardCircle isAgenda={true}>
                            <div className="image-content">
                                <div>
                                    <img src="/images/agenda.png" alt="imagem" />
                                </div>
                            </div>{" "}
                            <span>2</span>
                            <p>Recebe o portfólio dos fotógrafos disponíveis e escolhe seu favorito</p>
                        </CardCircle>

                        <CardCircle isAgenda={false}>
                            <div className="image-content">
                                <div>
                                    <img src="/images/people.png" alt="imagem" />
                                </div>
                            </div>{" "}
                            <span>3</span>
                            <p>
                                Nosso fotógrafo vai até você, faz as fotos no horário combinado e te envia o material em
                                seguida
                            </p>
                        </CardCircle>
                    </Slider>
                </CircleContent>

                <TitleAct>
                    <span>Atuação:</span>
                    <p>
                        <strong>Solicite um fotógrafo </strong>nas categorias abaixo
                    </p>
                </TitleAct>

                <CategoryContent>
                    <Slider {...categorySetting}>
                        {categories.map((category) => (
                            <CardCategory key={category.id} image={category.image} title={category.title} />
                        ))}
                    </Slider>
                    <a target="_blank" href="/marketplace/book">
                        Solicitar fotógrafo
                    </a>
                </CategoryContent>

                <DiferencesContent>
                    <span>Atribuição:</span>
                    <p>
                        {" "}
                        <strong>Diferenciais</strong> competitivos
                    </p>

                    <div className="wrap-carousel">
                        <Slider {...differencesSetting}>
                            <DifferencesCard image="/images/camera.png" title="Fácil de usar">
                                <p>
                                    Nem sempre é fácil conseguir fotos profissionais. Nós te conectamos aos melhores
                                    fotógrafos do Brasi.
                                </p>
                            </DifferencesCard>

                            <DifferencesCard image="/images/user-orange.png" title="Fotógrafos especializados">
                                <p>
                                    Contamos com parceiros especializados em cada segmento que atuamos, garantindo a
                                    melhor entrega para você e sua empresa.
                                </p>
                            </DifferencesCard>

                            <DifferencesCard image="/images/dolar.png" title="Venda mais">
                                <p>
                                    Uma foto profissional valoriza seu imóvel, destaca seu produto e eterniza suas
                                    lembranças.
                                </p>
                            </DifferencesCard>
                            <DifferencesCard image="/images/shield.png" title="Rápido e confiável">
                                <p>
                                    Tenha acesso a fotos profissionais de maneira segura, simples, prática e
                                    descomplicada.
                                </p>
                            </DifferencesCard>
                        </Slider>
                    </div>
                </DiferencesContent>
            </Content>
        </Container>
    );
};

export default HowItWorks;
